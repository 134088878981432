import React, { useContext } from "react";
import { Link } from "react-router-dom";
import Navbar from "./layout/navbar";
import tech from "../tech.png";
import styled from "styled-components";
import stech from "../stech.png";

const Styleddivimg = styled.div`
  text-align: center;
  padding-left: 4rem;
`;

const Styleddiv = styled.div`
  background-color: #f1f1f1;
  padding: 17px 10px 37px 10px;
  text-align: center;
`;

const Styleddiv1 = styled.div`
  background-color: #f1f1f1;
  padding: 17px 10px 37px 10px;
  text-align: center;
`;

const Styledbutton = styled.button`
  border: none;
  padding: 10px 24px 10px 24px;
  background: #000000;
  border-radius: 28px;
  font-size: 3vh;
  font-weight: 700;
  color: white;
  box-shadow: 3px 3px 1px 2px grey;
  margin-top: 2rem;
`;

const Styledh12 = styled.h1`
  padding-top: 2%;
  font-size: 5vh;
  text-align: center;
`;

const LandingPage = () => {
  return (
    <div className="updiv">
      <Navbar mainhead="about" />

      <div>
        <h1 className="styleh1 text-center">
          Find <span style={{ color: "#009688" }}>curated</span> blogs by a student
          developer <br />
          on topics that are worth reading
        </h1>
      </div>

      <Styleddivimg>
        <img className="imglanding" src={tech} />
      </Styleddivimg>

      <div className="mb-4">
        <p className="topland text-center mb-4">
          TOPICS <i class="fas fa-pencil-alt"></i>
        </p>
        <div className="landingskilldiv mb-4">
          <Link to="/blog/1">
            {" "}
            <div className="landingskillsmalldiv">ReactJs</div>
          </Link>
          <div className="landingskillsmalldiv">NodeJs</div>
          <div className="landingskillsmalldiv">Python</div>
          <div className="landingskillsmalldiv">ML</div>
          <div className="landingskillsmalldiv">Review Papers</div>
          <div className="landingskillsmalldiv">DS/ALGO</div>
          <div className="landingskillsmalldiv">AI</div>
          <div className="landingskillsmalldiv">Javascript</div>
          <div className="landingskillsmalldiv">HTML</div>
          <div className="landingskillsmalldiv">CSS</div>
          <div className="landingskillsmalldiv">DevOps</div>
          <div className="landingskillsmalldiv">Cloud</div>
          <div className="landingskillsmalldiv">Android</div>
          <div className="landingskillsmalldiv">Architectures</div>
        </div>
      </div>

      <Styleddiv1>
        <h1 style={{ fontSize: "5vh", fontWeight: "bold" }}>Get started with reading</h1>
        <Link to="/blogs">
          <Styledbutton>Get Started</Styledbutton>
        </Link>
      </Styleddiv1>

      <div
        className="mt-4 text-center"
        style={{ paddingLeft: "2%", paddingTop: "2%", paddingBottom: "1%" }}
      >
        <h2 className="landingh2">
          Want to get a <span style={{ color: "#009688" }}>BLOG</span> on a topic{" "}
        </h2>
        <p className="landingp"> name it </p>
      </div>

      <div className="mt-4 landingcontdiv">
        <Styledh12>Have any Doubt ?</Styledh12>
        <p
          className="text-center"
          style={{ fontSize: "2vh", color: "grey", fontWeight: "bold" }}
        >
          Contact the developer today
        </p>
        <div className="text-center">
          {/* pic */}
          <img
            className="landingimg"
            src="https://i.ibb.co/bJ8y2Jw/40017134-2042011202526098-3285397012161232896-n.jpg"
            alt="40017134-2042011202526098-3285397012161232896-n"
            border="0"
          />

          <p style={{ fontSize: "3vh", marginBottom: "0" }}>Vansh Kapoor</p>
          <p style={{ fontSize: "2vh" }}>Vanshkapoorvk7@gmail.com</p>
        </div>
      </div>
      {/* <div>
      <div className="row">
          <div className="col-lg-6 col-sm-">
              <h2></h2>
          </div>
          <div className="col-lg-6">

          </div>
      </div>
    </div> */}
    </div>
  );
};

export default LandingPage;
