import React, { useEffect, useState } from "react";
import Navbar from "../layout/navbar";
import Author from "./author";
import Content from "./content";
import Blogposts from "./blogposts";
import Parse from "parse";
import load from "../load.gif";
import Footer from "../footer/main";
import MetaTags from "react-meta-tags";

// import { parse } from 'querystring';

const Blog = (props) => {
  const [title, setTitle] = useState("");
  const [date, setDate] = useState("");
  const [blogcontent, setBlogcontent] = useState("");
  const [user, setUser] = useState("");
  const [stats, setStats] = useState("");
  const [final, setFinal] = useState(false);
  const [views, setViews] = useState("");
  const [theme, setTheme] = useState("");
  const [latest, setLatest] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loading1, setLoading1] = useState(false);
  let id = props.match.params.id;
  id = id.split("_").join(" ");
  const blog = Parse.Object.extend("Blogs");

  useEffect(() => {
    const query = new Parse.Query(blog);
    query.equalTo('title', id);
    setLoading(true);
    setLoading1(true);

    const options = { weekday: "long", year: "numeric", month: "long", day: "numeric" };

    query.first().then(
      (data) => {
        setTitle(data.get("title"));
        setUser(data.get("user"));
        setBlogcontent(data.get("data"));
        setFinal(data.get("final"));
        setStats(data.get("stats"));
        setTheme(data.get("theme"))
        setDate(data.updatedAt.toLocaleDateString(undefined, options));
        setViews(data.get("views"));
        let count = data.get("views");
        if (count == undefined || count == null) {
          count = 1;
        } else {
          count += 1;
        }
        data.set("views", count);
        data
          .save()
          .then((data) => {
          })
          .catch((error) => {
          });
      },
      (error) => {
      }
    );
    window.scrollTo(0, 0)
  }, []);


  useEffect(() => {
    const query2 = new Parse.Query(blog);
    query2.contains("final", "true").count().then(data => {
      console.log("dataaa ---", data)
      console.log(Math.random() * (data - 3))
        query2
        .notEqualTo('title', id)
        .contains("final", "true")
        .skip(Math.random() * (data - 3))
        .limit(3)
        .find()
        .then((data) => {
          setLatest(data);
          setLoading1(false);
        })
        .catch((error) => {
        });
    })
      
  }, [])


  return (
    <div className="updiv">
      <MetaTags>
        <title>{title}</title>
        <meta name="description" content="Vansh Kapoor" />
        <meta property="og:title" content="Vansh Kapoor" />
        <meta property="og:image" content={require("../auth/im1.jpg")} />
      </MetaTags>
      <Navbar mainhead="JOURNAL" blogData={{
        title,
        date: new Date(date).toLocaleDateString(),
        stats,
        user: user.split("@")[0],
        theme
      }} />

      <div className="row">
        {/* <Author user={user.split("@")[0]} email={user} /> */}
        <div className="col-lg-2 "></div>
        <Content
          title={title}
          date={date}
          data={blogcontent}
          final={final}
          stats={stats}
          views={views}
        />
        <div className="col-lg-2 "></div>
        <Blogposts latest={latest} />
      </div>
      <Footer />
    </div>
  );
};

export default Blog;
