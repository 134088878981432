import React from "react";

export const APILoadingComponent = (props) => {
    if(props.isLoading)
    {
        return <div class="spinner-border text-secondary mx-auto" role="status">
        <span class="sr-only">Loading...</span>
      </div>;
    };
    return props.children;
}