import React, { useState, useContext, useEffect } from "react";
import ReactDOM from "react-dom";
import MdEditor from "react-markdown-editor-lite";
import MarkdownIt from "markdown-it";
import showdown from "showdown";
import renderHTML from "react-render-html";
import Navbar from "../layout/navbar";
import { AuthContext } from "../../auth";
import Parse from "parse";
import { useAlert } from "react-alert";
import { Redirect } from "react-router-dom";
const readingTime = require("reading-time");

const MOCK_DATA =
  "Hey write on the left\n\n * This is markdown.\n * remove me before writing";
// let data="";

const Writefn = () => {
  // document.body.style.overflowX = "none !important";

  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [final, setFinal] = useState(false);
  const [data, setData] = useState("");
  const [theme, setTheme] = useState("");
  const [logedinuser, setLogedinuser] = useContext(AuthContext);
  const [email, setEmail] = useState("");
  const [img, setImg] = useState("");
  const alert = useAlert();

  let mdParser = new MarkdownIt();

  useEffect(() => {
    const profile = Parse.Object.extend("User");

    if (localStorage.getItem("id") != null) {
      setLogedinuser("id", localStorage.getItem("id"));
      const query = new Parse.Query(profile);
      query
        .get(localStorage.getItem("id"))
        .then((prof) => {
          setEmail(prof.get("username"));
        })
        .catch((err) => {
          alert.error("login again please");
        });
    } else {
      const query = new Parse.Query(profile);
      query
        .get(logedinuser)
        .then((prof) => {
          setEmail(prof.get("username"));
        })
        .catch((err) => {
        });
    }
  }, []);

  const handleEditorChange = ({ html, text }) => {
    setData(html);
  };

  const submit = (e) => {
    e.preventDefault();
    const blogs = Parse.Object.extend("Blogs");
    const blogdata = new blogs();
    if (data == "" || title == "" || theme == "" || description == "") {
      alert.show("Please fill the complete form");
      return;
    }
    const statdata = readingTime(data);
    blogdata.set("time", statdata.time);
    blogdata.set("data", data);
    blogdata.set("stats", statdata.text);
    blogdata.set("title", title);
    blogdata.set("theme", theme);
    blogdata.set("description", description);
    blogdata.set("final", final);
    blogdata.set("created by", logedinuser);
    blogdata.set("user", email);
    blogdata.set("imageLink", img);
    blogdata
      .save()
      .then((data) => {
        setTitle("");
        setData("");
        setDescription("");
        setFinal(false);
        setTheme("");
        alert.success("The blog is published!");
      })
      .catch((error) => {
        alert.error("Couldnt publish the blog at the moment");
      });

  };

  return (
    <div className="updiv" style={{ height: "800px" }}>
      <Navbar mainhead="JOURNAL" head="Create" />
      {/* <div style={{'marginLeft':'9%'}}>
            <h2>Create a Blog</h2>
            </div> */}
      {logedinuser == null && <Redirect to="/blogs" />}
      <MdEditor
        value={MOCK_DATA}
        renderHTML={(text) => mdParser.render(text)}
        onChange={handleEditorChange}
      />
      <div
        class="text-center mb-2 container"
        style={{ marginTop: "1rem", width: "40vh" }}
      >
        {/* <label for="inputState">Theme</label> */}
        <input
          type="text"
          class="form-control mb-2"
          onChange={(e) => setTitle(e.target.value)}
          value={title}
          placeholder="Title"
        />
        <input
          type="text"
          class="form-control mb-2"
          onChange={(e) => setImg(e.target.value)}
          value={img}
          placeholder="Image link"
        />
        <input
          type="text"
          class="form-control mb-2"
          onChange={(e) => setDescription(e.target.value)}
          value={description}
          placeholder="Description(25 words max)"
        />
        <select
          id="inputState"
          class="form-control"
          onChange={(e) => setTheme(e.target.value)}
        >
          <option selected>Choose Theme</option>
          <option value="nodejs">NodeJs</option>
          <option value="Reactjs">ReactJs</option>
          <option value="AI">AI</option>
          <option value="ML">ML</option>
          <option value="CP">competitive coding</option>
          <option value="JS">Javascript</option>
          <option value="c++">C++</option>
          <option value="DM">Digital Marketing</option>
          <option value="C++">C++</option>
          <option value="Review paper">Review Paper</option>
        </select>
        <select
          id="inputState"
          class="form-control mt-2"
          onChange={(e) => setFinal(e.target.value)}
        >
          <option selected>Set as draft ?</option>
          <option value={false}>Yes</option>
          <option value={true}>No</option>
        </select>
      </div>

      <div className="text-center mb-4" style={{ marginTop: "0.1rem" }}>
        <button className="btn btn-dark mt-2 " style={{ width: "25vh" }} onClick={submit}>
          Submit
        </button>
      </div>
    </div>
  );
};

export default Writefn;
