import React, { useState, useContext, useEffect } from "react";
import Navbar from "../layout/navbar";
import styled from "styled-components";
import Themecard from "./themecard";
import Blogcard from "./blogcard";
import Blogpopcard from "./blogpopcard";
import Parse from "parse";
import load from "../load.gif";
import Footer from "../footer/main";
import { APILoadingComponent } from "../Common/APILoadingComponent";

const Bloghome = () => {
  const [blogsdata, setBlogsdata] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loading1, setLoading1] = useState(false);
  const [latest, setLatest] = useState([]);
  const [listStyle, setListStyle] = useState(true)
  const [activePage, setActivePage] = useState(0)
  const [page, setPage] = useState(0)


  useEffect(() => {
    const blogs = Parse.Object.extend("Blogs");
    const query = new Parse.Query(blogs);
    setLoading(true);

    query.skip(activePage*10).limit(10)
    .contains("final", "true")
    .descending("createdAt").find().then((blog) => {
      setBlogsdata(blog);
      setLoading(false);
    });
    window.scrollTo(0, 0)
  }, [activePage]);

  useEffect(() => {
    const blogs = Parse.Object.extend("Blogs");
    const query = new Parse.Query(blogs);
    setLoading1(true);

    query.count().then(data => {
      setPage(Math.floor(data/10));
      setLoading1(false);
    });

  }, [])


  const fetchUniqueThemes = () => {
    return ["Js", "Reactjs", "Review paper", "Github", "DevOps", "System Design", "Backend"];
  }

  const options = { weekday: "long", year: "numeric", month: "long", day: "numeric" };
  
  return (
    <div className="updiv">
      <Navbar head="explore" mainhead="JOURNAL" />

      <div className="row">
        <div className="col-lg-2 col-md-2 col-sm-0">
        </div>
        <div className="col-lg-8 col-md-8 col-sm-12">
          <br />
          <br />
          <div className="container" style={{borderRight: "0px solid"}}>
            <div className="d-flex" style={{justifyContent: "space-between"}}>
              <h3 className="pt-4"><b>Articles</b> </h3>
              <h3 className="pt-4">
              <div className="btn-group" role="group" aria-label="Basic example">
                <button type="button" className={listStyle?"btn btn-outline-dark active":"btn btn-outline-dark"} onClick={() => setListStyle(true)}><i className="fas fa-bars"></i></button>
                <button type="button" className={listStyle?"btn btn-outline-dark":"btn btn-outline-dark active"} onClick={() => setListStyle(false)}><i className="fa fa-th-list"></i></button>
              </div>
              </h3>
            </div>
            <div className="d-flex mt-4  bloghome-themes-div">
              {fetchUniqueThemes().map((theme, index) => <div className={ index>0 ? "pl-2 pr-2 mr-2 ml-2 mb-2 themeborder" : "pl-2 pr-2 mr-2 mb-2 themeborder" }>
                <p className="mb-0">{theme}</p></div>)}
            </div>
            <br/>
            <br/>
            <br/>
            <div class="row gx-2 gy-2 ">
              <APILoadingComponent isLoading={loading}>
                {blogsdata.map((blog) => {
                return <Blogcard
                  id={blog.id}
                  title={blog.get("title")}
                  views={blog.get("views")}
                  user={blog.get("user")}
                  theme={blog.get("theme")}
                  stats={blog.get("stats")}
                  date={blog.createdAt.toLocaleDateString(undefined, options)}
                  imgLink={blog.get("imageLink")}
                  description={blog.get("description")}
                  listStyle={listStyle}
                />
                })}

              </APILoadingComponent>
            </div>
            <br />
            <div style={{
              display: "flex", justifyContent: "end", marginTop: "30px"
            }}>
                {[...Array(page+1)].map((e, i) => <button onClick={()=> {
                      setActivePage(i)
                    }
                    } 
                                    type="button"
                                    className={i == activePage ? "btn btn-dark":"btn btn-light"}>
                          {i+1}
                    </button>
              )}
              { (activePage<page) && <button onClick={()=> { 
                  setActivePage(activePage+1);
                 }}
                  type="button"
                  className="btn btn-dark">
                    Next
                  </button>
                }
              </div>
            <br />
            <br />
          </div>
        </div>
        <div className="col-lg-2 col-md-2 col-sm-0">
        </div>
      </div>
      <br />
      <Footer />
    </div>
  );
};

export default Bloghome;
