import React from 'react'

const Notfound =() =>{
    return(
        <div>
            <h1>
                No page !!
            </h1>
        </div>
    )
}

export default Notfound;