import React from 'react'
import ReactDOM from 'react-dom'
import MdEditor from 'react-markdown-editor-lite'
import MarkdownIt from 'markdown-it'
import showdown from 'showdown'
import renderHTML from 'react-render-html';

const MOCK_DATA = "Hello.\n\n * This is markdown.\n * It is fun\n * Love it or leave it."
let data="";
export default class Write extends React.Component {
  mdParser = null
  converter =null;
  constructor(props) {
    super(props)
    this.mdParser = new MarkdownIt(/* Markdown-it options */)
    this.state={
        html:''
    }
    // this.converter = new showdown.Converter()
  }

  handleEditorChange ({html, text}) {    
    //  setState({html:html})
    data =  html;
  }

  submit=()=>{
  }
  
  render() {
      var conv = new showdown.Converter();
      var html = conv.makeHtml("# fjkfnklew");
    //   obj = html;
  let obj = <div>html{html}</div>
    return (      
      <div style={{"height": "500px"}}>
        <MdEditor
          value={MOCK_DATA}
          renderHTML={(text) => this.mdParser.render(text)}
          onChange={this.handleEditorChange} 
        />           
        <p>{obj}</p>     
        {html}
        
        {renderHTML(html)}
        <hr />

    <button onClick={this.submit} > send to server</button>
      </div>
    )
  }
}


// const Write=()=>{
//     return(
//         <div>

//         </div>
//     )
// }

// export default Write;