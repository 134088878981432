import React from 'react'
import {Link} from 'react-router-dom'

const Author =(props)=>{
    return(
        <div className="col-lg-2 ">
            <div className="mainblog">
                <div className="authordiv">{props.user[0]}</div>
                {/* <p><strong>Written By</strong></p> */}
                <p className="mainblogleftp">
                <p style={{marginBottom:0,color:'grey'}}><strong>Written By</strong></p>
                    <Link to={`/profile/${props.email}`} style={{textDecoration:'none',color:"black",fontSize:'20px'}}>{props.user}</Link></p>
            </div>
            <div className="mainblogleftpdown">
                <p>read more from <i ><Link to={`/profile/${props.email}`} style={{textDecoration:'none',color:'#009688'}}>{props.user}</Link></i></p>
                <hr style={{'width':'40%','float':'left'}}/>
            </div>
            <hr className="hrleft"/>
        </div>
    )
}

export default Author;