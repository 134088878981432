import React from "react";
import { Link } from "react-router-dom";

const Profilecard = (props) => {
  const date = props.date.split(",")[1].split(" ");

  return (
    <div className="col-lg-6 col-md-12">
      <div className="card blogcard2 mb-3">
        {/* <div className="views">
            views:{props.views}
        </div>                        */}
        <div className="profilecardrow row">
          <div className="col-4">
            <img className="blogcardimg" src={props.imgLink} />
          </div>
          <div className="col-8">
            <Link
              to={`/blog/${props.id}`}
              style={{ color: "black", textDecoration: "none" }}
            >
              <h3 className="card-title blogcardtitle">
                <strong>{props.title}</strong>
              </h3>
            </Link>
            <div className="blogleft">
              <div className="d-flex">
                <p className="nameblogcard">
                  by{" "}
                  <span style={{ fontWeight: "bold" }}>{props.user.split("@")[0]} </span>
                </p>
                <p className="pl-2">
                  views: <b style={{ color: "#009688" }}> {props.views}</b>
                </p>
              </div>
              {/* <p>by {props.user} </p> */}
              <p className="datablogcard">
                {date[1] + " " + date[2].slice(0, 3) + "," + " " + date[3]} •{" "}
                {props.stats}
              </p>
              {/* <p>{date[1] + " " + date[2].slice(0,3) + "," + " " + date[3] } • {props.stats} </p> */}
            </div>
            <div className="blogright">
              <p className="blogrightnodejs">{props.theme}</p>
              {/* <div class="dropdown show">
                    <a class="dropdown-toggle" href="#" role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    </a>

                    <div class="dropdown-menu" aria-labelledby="dropdownMenuLink">
                    <a class="dropdown-item" href="#">Action</a>
                    <a class="dropdown-item" href="#">Another action</a>
                    <a class="dropdown-item" href="#">Something else here</a>
                    </div>
                    </div>                                                                            */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Profilecard;
