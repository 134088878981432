import React, { Component } from "react";
// import logo from './logo.svg';
import "./App.css";
import "./Styles/font.css";
import "./Styles/colors.css"
import { positions, Provider } from "react-alert";
import AlertTemplate from "react-alert-template-basic";

import AuthProvider from "./auth.js";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import LandingPage from "./components/landingPage";
import Login from "./components/auth/login";
import Register from "./components/auth/register";
import Profile from "./components/profile/profile";
import Bloghome from "./components/Bloghome/Bloghome";
import Notfound from "./components/notfound";
import Blog from "./components/Blog/blog";
import Write from "./components/written/write";
import Writefn from "./components/written/writefn";
import Eprofile from "./components/profile/Eprofile";
import Demand from "./components/Demand/demand";

const Parse = require("parse");
Parse.initialize(
  "9R61D1Gv6qqos0q5sBmSK4AywmPvwef7FQLyKvqY",
  "q1CjPh7MMyEEFGGAsWyDFi5gJb5vkO8y4yg6h2pl"
);
Parse.serverURL = "https://parseapi.back4app.com/";

const options = {
  timeout: 5000,
  position: positions.BOTTOM_CENTER,
};

class App extends Component {
  render() {
    return (
      <Router>
        <Provider template={AlertTemplate} {...options}>
          <AuthProvider>
            <div className="App">
              <Switch>
                <Route exact path="/login" component={Login} />
                <Route exact path="/register" component={Register} />
                <Route exact path="/about" component={LandingPage} />
                <Route exact path="/" component={Bloghome} />
                <Route exact path="/profile" component={Profile} />
                <Route exact path="/blogs" component={Bloghome} />
                <Route exact path="/blog/:id" component={Blog} />
                <Route exact path="/write" component={Write} />
                <Route exact path="/writefn" component={Writefn} />
                <Route exact path="/profile/:id" component={Eprofile} />
                <Route exact path="/demandslist" component={Demand} />
                <Route component={Notfound} />
              </Switch>
            </div>
          </AuthProvider>
        </Provider>
      </Router>
    );
  }
}
export default App;
