import React, { useContext, useState, useEffect } from "react";
import "./navbar.css";
import styled from "styled-components";
import { AuthContext } from "../../auth";
import { Link, withRouter, Redirect } from "react-router-dom";
import Parse from "parse";
import { useAlert } from "react-alert";
import { featureToggle } from "../../feature-toggle";
import { NavbarEffect } from "./navbarEffect";
// import {Redirect} from 'react-router-dom';

const StyledDiv = styled.div`
`;
const Styledh2 = styled.h2`
  color: #000000;
  font-size: 1.6rem;
`;

const Styledbutton = styled.button`
  background: #e9e9e9;
  border: none;
  padding: 10px;
  border-radius: 12px;
  color: #75cbfd;
`;

const Navbar = (props) => {
  const [logedinuser, setLogedinuser] = useContext(AuthContext);
  const [request, setRequest] = useState("");
  const alert = useAlert();
  const {top} = NavbarEffect();

  useEffect(() => {
    if (localStorage.getItem("id") != null) {
      setLogedinuser("id", localStorage.getItem("id"));
    }
  }, []);


  const hanldeSubmit = () => {
    const req = Parse.Object.extend("request");
    const requestObj = new req();
    requestObj.set("user", logedinuser);
    requestObj.set("request", request);
    requestObj
      .save()
      .then((data) => {
        alert.success("Demand is done :)");
      })
      .catch((error) => {
        alert.error("Couldnt send your demand! Try again");
      });
  };

  const logout = () => {
    localStorage.removeItem("id");
    window.location.reload();
  };

  const closeNav = () => {
    document.getElementById("mySidenav").style.width = "0";
  };

  const openNav = () => {
    document.getElementById("mySidenav").style.width = "250px";
  };


  return (
    <StyledDiv>
      {/* Mobile Navbar */}
      <div id="mySidenav" className="sidenav" style={{ zIndex: "1003" }}>
        <div className="navhead">
          <strong>Vansh Kapoor</strong>
          <br />
        </div>
        <a href="javascript:void(0)" className="closebtn" onClick={closeNav}>
          &times;
        </a>
        <div className="navcontent">
          {featureToggle.USER_LOGIN_ENABLED && ( !logedinuser ? <NewUserRightNav head={props.head} /> 
          : <SignedInUserRightNav head={props.head} />)}
          <Link to="/blogs">
            <i
              className={
                props.head == "explore" ? "fas fa-book-open black" : "fas fa-book-open"
              }
            ></i>{" "}
            Blogs
          </Link>
          <p className="navrequest" data-toggle="modal" data-target="#exampleModal">
            <i
              className={
                props.head == "request" ? "fas  fa-users black" : " fas fa-users"
              }
            ></i>{" "}
            Let's Connect👋
          </p>
        </div>
      </div>
      
      <span className="navmobile" onClick={openNav}>
            &#9776;
      </span>
      
      {/* Desktop Navbar */}
      <div className="navlayoutlogin" >
        <div>
          <Styledh2>
            <Link
              to="/blogs"
              className="header-name"
            >
              <span>Vansh Kapoor</span>
            </Link>
          </Styledh2>
          <div className="navright">
            <Link to="/blogs" style={{ color: "black", textDecoration: "none" }}>
              <h2
                className={
                  props.head == "explore" ? "navrighttags active" : "navrighttags"
                }
              >
                Blogs
              </h2>
            </Link>
            <h2
              data-toggle="modal"
              data-target="#exampleModal"
              className="navrighttags letsconnect"
            >
              Let's Connect👋
            </h2>
            { featureToggle.USER_LOGIN_ENABLED && ( logedinuser ? <RightSideNavDesktop head={props.head} logout={logout} /> : (
              <Link to="/register" style={{ color: "black", textDecoration: "none" }}>
                <h2
                  className={
                    props.head == "register" ? "navrighttags black" : "navrighttags"
                  }
                >
                  Register
                </h2>
              </Link>
            )) }
          </div>
        </div>
        { props.blogData && <div className="navBlogDiv">
                <p className="navblogtime"> {props.blogData.date || "__/__/__"} • {props.blogData.stats || "__"} </p> 
                <h2 className="navblogheader">{props.blogData.title || "__ ____"} </h2>
                <p className="navblogauthor"> By {props.blogData.user || "____"}</p> 
                <div style={{display: "flex", justifyContent: "center"}}>
                <p className="navbartheme">{props.blogData.theme || "__"}</p>
                </div>
          </div>
        }
       
    
      </div>
      <div className={top==1?"navlayoutbar navActive": "navlayoutbar navHidden"}>
        <div>
          <Styledh2>
            <Link
              to="/blogs"
              className="header-name"
            >
              <span>Vansh Kapoor</span>
            </Link>
          </Styledh2>
          <div className="navright">
            <Link to="/blogs" style={{ color: "black", textDecoration: "none" }}>
              <h2
                className={
                  props.head == "explore" ? "navrighttags active" : "navrighttags"
                }
              >
                Blogs
              </h2>
            </Link>
            <h2
              data-toggle="modal"
              data-target="#exampleModal"
              className="navrighttags letsconnect"
            >
              Let's Connect👋
            </h2>
          </div>
        </div>
      </div>
    </StyledDiv>
  );
};


const NewUserRightNav = (props) =>{
  return (
    <>
      <Link to="/login">
        <i
          className={
            props.head == "login" ? "fas fa-columns black" : "fas fa-columns"
          }
        ></i>{" "}
        LOGIN
      </Link>
      <Link to="/register">
        <i
          className={
            props.head == "register" ? "fas fa-columns black" : "fas fa-columns"
          }
        ></i>{" "}
        REGISTER
      </Link>
    </>
  )
} 

const SignedInUserRightNav = (props) => {
  return (
    (
      <>
        <Link to="/profile">
          <i
            className={
              props.head == "Profile" ? "fas fa-columns black" : "fas fa-columns"
            }
          ></i>{" "}
          PROFILE
        </Link>
        <Link to="/writefn">
          <i
            className={
              props.head == "Create" ? "fas  fa-comments black" : " fas fa-comments"
            }
          ></i>{" "}
          WRITE UP
        </Link>
      </>
    )
  )
}

const RightSideNavDesktop = (props) => {
  return (
    <>
    <Link to="/writefn" style={{ color: "black", textDecoration: "none" }}>
      <h2
        className={
          props.head == "Create" ? "navrighttags black" : "navrighttags"
        }
      >
        WRITE
      </h2>
    </Link>
    <div class="dropdown">
    <button
      class="btn btn-secondary dropdown-toggle"
      type="button"
      id="dropdownMenuButton"
      data-toggle="dropdown"
      aria-haspopup="true"
      aria-expanded="false"
      style={{ color: "black", background: "#ececec", border: "none" }}
    >
      <i class="fas fa-user-circle"></i>
    </button>
    <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
      <Link to="/profile" style={{ textDecoration: "none" }}>
        <p class="dropdown-item">View Profile</p>
      </Link>
      <p class="dropdown-item" onClick={props.logout}>
        Logout
      </p>
    </div>
  </div>
    </>
  )
}
export default withRouter(Navbar);
