import React from 'react'
// import renderHTML from 'react-render-html';
import renderHTML, {
    applyMiddleware
  } from 'react-render-html';
import ReactMarkdown from "react-markdown";
import rehypeRaw from "rehype-raw";
import SyntaxHighlighter from 'react-syntax-highlighter';
import { nightOwl } from 'react-syntax-highlighter/dist/esm/styles/hljs';

const Content =(props)=>{
    console.log(props.data)
    return(
        <div className="col-lg-8 ">
        <div className="bdiv">
            <div className="mainblogcontent">
                <br />
                <br />
                <br />
                <ReactMarkdown 
                    children={props.data}
                    components={{
                        code(props) {
                            return <SyntaxHighlighter language="javascript" style={nightOwl}>
                            {props.children}
                          </SyntaxHighlighter>
                        }
                    }}
                    rehypePlugins={[rehypeRaw]} 
                />
                <br />
                <br />
                <br />
                <br />
            </div>
        </div>
    </div>
    )
}

export default Content