import React, { useEffect } from "react";
import Navbar from "../layout/navbar";
import { useState } from "react";
import Parse from "parse";

const Demand = () => {
  const [demands, setDemands] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const demands = Parse.Object.extend("request");
    const query = new Parse.Query(demands);
    query.find().then((dmnd) => {
      setDemands(dmnd);
      setLoading(false);
    });
  });

  let emojiobj = ["💬 ", "⚡", "🔭", "🌱"];

  return (
    <div>
      <Navbar head="demands" mainhead="JOURNAL" />
      <div className="container">
        <br />
        <br />
        <br />
        <br />
        <br />

        <h2 style={{ color: "#009688" }}>Demand List 🤔 :-</h2>
        <hr />
        <br />
        {loading ? (
          <div class="spinner-border text-secondary mx-auto" role="status">
            <span class="sr-only">Loading...</span>
          </div>
        ) : (
          demands.map((obj, index) => {
            return (
              <p key={index} style={{ fontSize: "19px", marginBottom: "1rem" }}>
                <span className="pr-2">{emojiobj[index % 4]}</span>
                {obj.get("request")}
              </p>
            );
          })
        )}
      </div>
    </div>
  );
};

export default Demand;
