import React from "react";
import { Link } from "react-router-dom";

const Blogcard = (props) => {
  const ndate = new Date(props.date).toLocaleDateString();
  return (
    <div className={ props.listStyle ? "col-lg-12 col-md-12 blogcard-padding blogCardCol" : "col-lg-6 col-md-12 blogcard-padding blogCardCol"}>
      <div className={props.listStyle?"card blogcard border-none":"card blogcard"}>
        <div className="row bloghomecard">
          {props.listStyle && <div className="col-3">
            <img
              className="blogcardimg"
              src={props.imgLink}
            />
          </div>}
          <div className={ props.listStyle ? "col-9" : "col-12" }>
            {!props.listStyle && <img
              className="blogcardimg"
              src={props.imgLink}
            />}
            <div className={ props.listStyle ? "": "BlogContentPadded"}>
                <div className="d-flex mb-2">
                  <p className="mb-0 faded" >{ndate} </p>
                  <p className="mb-0 pl-2 pr-2 faded">•</p> 
                  <p className="mb-0 datablogcard faded"> {props.stats} </p>
                </div>
                <Link
                  to={`/blog/${props.title.split(" ").join("_")}`}
                  style={{ color: "black", textDecoration: "none" }}
                >
                  <h3 className="card-title blogcardtitle large-heading mb-0">
                    <strong>
                      {props.title}
                    </strong>
                  </h3>
                </Link>
                <div className="blogleft mb-0 ">
                    <p className="mb-2 ">
                      {props.description}
                    </p>
                    <p className="blogrightnodejs">{props.theme}</p>
                </div>
                <br/>
                { props.listStyle && <div className="custom-hr"></div>}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Blogcard;
