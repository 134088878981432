import React, { useContext,useState,useEffect } from "react";
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import './login.css';
import Navbar from '../layout/navbar'
import img1 from './im1.jpg'
import Parse from 'parse';
import {Redirect} from 'react-router-dom'
import { AuthContext } from '../../auth';



const Styleddiv = styled.div`
background:#00c7e2;
box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25)
`
const Styledrow = styled.div`
height: -webkit-fill-available
`
const Styledbox = styled.div`
padding: 11px 13px 11px 13px;
`
const Styledform = styled.form`
margin: auto;
width: 78%;
`
const Styledh1 = styled.h1`
position: absolute;
bottom: 0;
color: white;
font-size: 4rem;
margin-left: 3%;`

const Register = () => {
  const [name,setName] = useState("")
  const [email,setEmail] = useState("")
  const [password,setPassword] = useState("")
  const [loaded,setLoaded] = useState(false)
  const [done,setDone] = useState(false)
  const [error, setError] = useState(false)
  const [success, setSuccess] = useState(false)
  const [logedinuser, setLogedinuser] = useContext(AuthContext)

  useEffect(() =>{

    if(localStorage.getItem('id')!=null){
      setLogedinuser('id',localStorage.getItem('id'))
    }

  },[])

  const handleSubmit =(e)=>{
    e.preventDefault();
    setLoaded(true)
    var user = new Parse.User;

    var Profile = Parse.Object.extend('Profile')
    var profile = new Profile();

    user.set("username",email)
    user.set("name",name)
    user.set("password",password)
    user.set('cpassword',password)
    user.signUp().then((data) => {
        profile.set('userId',data.id)        
        profile.set('name',name)
        profile.set('email',email)
        profile.save()
        .then((profile)=>{

            setLoaded(false)
            setDone(true)
            setError(false)
            setSuccess(true)
        })

    }).catch(err => {
        setLoaded(false);
        setError(true)
        // alert(err)
        // div = <p>error</p>
    })
  }

  return (
    <div className="updivlogin">
        {(logedinuser != null) && (
          <Redirect to="/blogs" />
      )}
      {(done == true)&&(
          <Redirect to="/login" />
      )}
      <Navbar  mainhead="JOURNAL" head="register"/>
      {error == true ? <div class="alert alert-danger alertpopup" style={{'margin':'auto'}} role="alert">
  Couldnt login! Please check your email or password
</div>:
<div>
  </div>}
  {success == true?<div class="alert alert-success" role="alert">
  successfully registered
</div>:null}
      <div style={{'textAlign':'center','paddingTop':'5em'}}>
        <img style={{'width':'11vh'}} src={img1} />
      </div>
      <div>
      <div className="loginbox">
            <h1 className="mb-4 text-center" >REGISTER</h1>
            <Styledform onSubmit={handleSubmit}>
            <div class="form-group">
              {/* <label for="exampleInputEmail1">Email address</label> */}
              <input  type="name" 
                      class="form-control inputb"
                      id="exampleInputName1"
                      aria-describedby="nameHelp"
                      placeholder="Enter name" 
                      value={name}
                      onChange={e => setName(e.target.value)}
                      />
            </div>
            <div class="form-group">            
            <input  type="email" 
                class="form-control inputb"
                id="exampleInputEmail1"
                aria-describedby="emailHelp"
                placeholder="Enter email" 
                value={email}
                onChange={e => setEmail(e.target.value)}
                />
            </div>
            <div class="form-group">
              {/* <label for="exampleInputPassword1">Password</label> */}
              <input  type="password" 
                      class="form-control inputb"
                      id="exampleInputPassword1" 
                      placeholder="Password"
                      value={password}
                      onChange={e => setPassword(e.target.value)}
                      />
            </div>
            <div class="form-group form-check">
              <input type="checkbox" class="form-check-input" id="exampleCheck1" />
              <label class="form-check-label" for="exampleCheck1">Agree to terms and conditions</label>
            </div>
                <button  type="submit" class="btn btn-primary buttonb">{loaded?'loading..':'SIGN UP'}</button>
            <p className="mt-2 text-center" style={{'color':'#585858'}}> Already User ? <Link to="/login" style={{'color':'#009688'}}>SIGN IN</Link></p>
          </Styledform>
          </div>
      </div>

      <div className="loginfoot">
        <p className="loginfootp">Journal &copy; 2019 </p>
      </div>
    </div>
  );
}

export default Register;
