import React, { useState, useContext, useEffect } from "react";
import Navbar from "../layout/navbar";
import styled from "styled-components";
import Profilecard from "./Profilecard";
import { AuthContext } from "../../auth";
import { Redirect } from "react-router-dom";
import Parse from "parse";
import { useAlert } from "react-alert";
import load from "../load.gif";
import Footer from "../footer/main";
const Styleddivleft = styled.div`
  width: 50%;
  float: left;
`;

const Styleddivright = styled.div`
  width: 50%;
  float: right;
  padding-top: 13px;
`;
const Profile = () => {
  const [logedinuser, setLogedinuser] = useContext(AuthContext);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [image, setImage] = useState("");
  const [blogs, setBlogs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [loading2, setLoading2] = useState(true);
  const [corearea, setCorearea] = useState("");
  const alert = useAlert();

  useEffect(() => {
    const profile = Parse.Object.extend("User");
    const blog = Parse.Object.extend("Blogs");
    const query2 = new Parse.Query(blog);

    setLoading(true);
    setLoading2(true);

    if (localStorage.getItem("id") != null) {
      setLogedinuser("id", localStorage.getItem("id"));
      const query = new Parse.Query(profile);
      query
        .get(localStorage.getItem("id"))
        .then((prof) => {
          setLoading(false);
          setName(prof.get("name"));
          setEmail(prof.get("username"));
          setCorearea(prof.get("corearea"));
          query2
            .equalTo("user", prof.get("username"))
            .find()
            .then((data) => {
              setBlogs(data);
              setLoading2(false);
            });
        })
        .catch((err) => {
          // alert(err)
        });
    }
    const query = new Parse.Query(profile);
    query
      .get(logedinuser)
      .then((prof) => {
        setName(prof.get("name"));
        setEmail(prof.get("username"));
        setCorearea(prof.get("corearea"));
        setLoading(false);
      })
      .catch((err) => {
      });
  }, []);

  const updateCorearea = (e) => {
    e.preventDefault();
    const profile = Parse.Object.extend("User");
    const query = new Parse.Query(profile);
    let logedin;
    if (localStorage.getItem("id")) {
      logedin = localStorage.getItem("id");
    }
    query
      .get(logedin)
      .then((obj) => {
        obj.set("corearea", corearea);
        obj
          .save()
          .then((data) => {
            alert.success("updated your profile");
          })
          .catch((error) => {
            alert.error("could not update at the moment");
          });
      })
      .catch((error) => {
        alert.error("could not update at the moment");
      });
  };

  const options = { weekday: "long", year: "numeric", month: "long", day: "numeric" };

  return (
    <div className="updiv">
      {logedinuser == null && <Redirect to="/blogs" />}
      <Navbar mainhead="JOURNAL" head="Profile" />
      <div className="row" style={{ paddingTop: "2rem", paddingBottom: "1%" }}>
        <div className="col-lg-2 col-md-2 col-sm-12 "></div>

        <div className="col-lg-4 col-md-4 col-sm-12">
          <div>
            <Styleddivleft>
              {/* <img className="coverimg" src="https://i.ibb.co/bJ8y2Jw/40017134-2042011202526098-3285397012161232896-n.jpg" alt="40017134-2042011202526098-3285397012161232896-n" border="0" /> */}
              {image ? (
                <img
                  className="coverimg"
                  src="https://i.ibb.co/bJ8y2Jw/40017134-2042011202526098-3285397012161232896-n.jpg"
                  alt="40017134-2042011202526098-3285397012161232896-n"
                  border="0"
                />
              ) : (
                <div className="Profilename">{name[0]}</div>
              )}
            </Styleddivleft>
            <Styleddivright>
              <h2 className="coverh1">{name}</h2>
              <div>
                <p className="coverp">email : {email}</p>
                {/* <p className="coverp">stars</p> */}
                {/* <p>followers : 120</p> */}
                <p className="coverp">
                  Total writeups : <b style={{ color: "#009688" }}>{blogs.length}</b>
                </p>
              </div>
            </Styleddivright>
          </div>
        </div>

        <div className="col-lg-4 col-md-4 col-sm-12 new_profile">
          <h1 className="coreh1">CORE AREA </h1>
          {/* update modal */}
          <div
            class="modal fade"
            id="coreareaModal"
            tabindex="-1"
            role="dialog"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <div class="modal-dialog" role="document">
              <div class="modal-content">
                <div class="modal-header">
                  <h5 class="modal-title" id="exampleModalLabel">
                    Update Core Area
                  </h5>
                  <button
                    type="button"
                    class="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div class="modal-body">
                  <p>Enter your Core areas </p>
                  <p>seperated with commas (,)</p>
                  <input
                    type="text"
                    class="form-control inputb"
                    placeholder="eg) please write a blog on grid system css"
                    name="request"
                    value={corearea}
                    onChange={(e) => setCorearea(e.target.value)}
                  />
                </div>
                <div class="modal-footer">
                  <button type="button" class="btn btn-secondary" data-dismiss="modal">
                    Close
                  </button>
                  <button type="button" class="btn btn-primary" onClick={updateCorearea}>
                    Update
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div>
            {/* <p><span className="mainprofilespan">Lorem ipsum</span><span className="mainprofilespan">Lorem ipsum</span><span className="mainprofilespan">Lorem ipsum</span><span className="mainprofilespan">Lorem ipsum</span><span className="mainprofilespan">Lorem ipsum</span><span className="mainprofilespan">Lorem ipsum</span></p> */}
            {/* <p className="mainprofilespan">lorem ipsum</p> */}
            {/* <p className="mainprofilep">lorem ipsum snjfe dnjefje dnjfej enjefj denjnej</p> */}
            {
              <i
                data-toggle="modal"
                data-target="#coreareaModal"
                class="profileedit fas fa-edit"
              ></i>
            }

            <div className="landingskilldivprofile mb-4">
              <br />

              {loading ? (
                <img className="loading" src={load} />
              ) : corearea != undefined ? (
                corearea
                  .split(",")
                  .map((core) => (
                    <div className="landingskillsmalldivprofile">{core.trim()}</div>
                  ))
              ) : (
                <p>No areas defined !</p>
              )}
              {/* <div className="landingskillsmalldivprofile">NodeJs</div>
                        <div className="landingskillsmalldivprofile">Python</div>
                        <div className="landingskillsmalldivprofile">ML</div>
                        <div className="landingskillsmalldivprofile">Architectures</div> */}
            </div>
          </div>
        </div>

        <div className="col-lg-2 col-md-2 col-sm-12"></div>
      </div>
      <hr className="profilemainhr" />

      <div className="profilecard">
        <h2 className="h2mainprofile mb-4">
          Blogs from <i>{name}</i>
        </h2>
        <div className="profilecardrowmain row mb-4 ">
          {loading2 ? (
            <div class="spinner-border text-secondary mx-auto" role="status">
              <span class="sr-only">Loading...</span>
            </div>
          ) : (
            blogs.map((data) => (
              <Profilecard
                title={data.get("title")}
                id={data.id}
                user={data.get("user")}
                views={data.get("views")}
                theme={data.get("theme")}
                date={data.updatedAt.toLocaleDateString(undefined, options)}
                imgLink={data.get("imageLink")}
                stats={data.get("stats")}
              />
            ))
          )}
          {/* <Profilecard />
                <Profilecard />
                <Profilecard /> */}
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Profile;
