import React from 'react'
import {Link} from 'react-router-dom'

const Blogposts=(props)=>{
    return(
        <div className="container">
            <h2 className="new_home_h3" style={{textAlign: "center"}}>Read More</h2>
            <hr />
            <br />
            <br />
            
            <div className='row container'>
            {props.latest.map(blog =>
                (
                    <div className='col-xs-10 col-lg-4 mb-4'>
                        <img
                            className="blogcardimg"
                            src={blog.get("imageLink")}
                            />
                        <p className="blogpostscardp card-title">
                        <a className='blogpostscardp' href={`/blog/${blog.get("title").split(" ").join("_")}`} >{blog.get("title")}</a>
                        <br/>
                            <span className="blogpostspan">{blog.get("theme")} • {blog.get("stats")}</span>
                        </p>
                    </div>
                ))}
            </div>
            <br />
            <br />
        </div>
    )
}


export default Blogposts