import React, { useEffect, useState } from "react";


export const NavbarEffect = () => {
    const [prevscroll, setPrevscroll] = useState(window.scrollY);
    const [top, setTop] = useState(0);

    const listener = (e) => {
        const currScroll = window.scrollY;
        if(currScroll < 150)
        {
            setTop(0);
            return;
        }
        if (prevscroll > currScroll) {
            setTop(1); // Show navbar
          } else {
            setTop(0); // Hide navbar
          }
        console.log(currScroll, "currScroll", prevscroll, "prevScroll");
        setPrevscroll(currScroll);
    }

    useEffect(() => {
        window.addEventListener("scroll", listener)
        return () => {
            window.removeEventListener("scroll", listener)
        }
    })

    return {
        prevscroll,
        top
    }

}

