import React, { useState, useContext, useEffect } from "react";
import Navbar from "../layout/navbar";
import styled from "styled-components";
import Profilecard from "./Profilecard";
import { AuthContext } from "../../auth";
import { Redirect } from "react-router-dom";
import Parse from "parse";
import { useAlert } from "react-alert";
import load from "../load.gif";
import Footer from "../footer/main";

const Styleddivleft = styled.div`
  width: 50%;
  float: left;
`;

const Styleddivright = styled.div`
  width: 50%;
  float: right;
  padding-top: 13px;
`;
const Eprofile = (props) => {
  const [logedinuser, setLogedinuser] = useContext(AuthContext);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [image, setImage] = useState("");
  const [blogs, setBlogs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [loading2, setLoading2] = useState(true);
  const [corearea, setCorearea] = useState("");
  const alert = useAlert();

  useEffect(() => {
    const profile = Parse.Object.extend("User");
    const blog = Parse.Object.extend("Blogs");
    const query2 = new Parse.Query(blog);

    setLoading(true);
    setLoading2(true);

    query2
      .equalTo("user", props.match.params.id)
      .find()
      .then((blogdata) => {
        setBlogs(blogdata);
        setLoading2(false);
      });

    const query = new Parse.Query(profile);
    query
      .equalTo("username", props.match.params.id)
      .find()
      .then((prof) => {
        setName(prof[0].get("name"));
        setEmail(prof[0].get("username"));
        setCorearea(prof[0].get("corearea"));
        setLoading(false);
      })
      .catch((err) => {
        // alert(err)
      });
  }, []);

  const options = { weekday: "long", year: "numeric", month: "long", day: "numeric" };

  return (
    <div className="updiv">
      <Navbar mainhead="JOURNAL" head="Profile" />
      <div className="row" style={{ paddingTop: "2rem", paddingBottom: "1%" }}>
        <div className="col-lg-2 col-md-2 col-sm-12 "></div>

        <div className="col-lg-4 col-md-4 col-sm-12">
          <div>
            <Styleddivleft>
              {/* <img className="coverimg" src="https://i.ibb.co/bJ8y2Jw/40017134-2042011202526098-3285397012161232896-n.jpg" alt="40017134-2042011202526098-3285397012161232896-n" border="0" /> */}
              {image ? (
                <img
                  className="coverimg"
                  src="https://i.ibb.co/bJ8y2Jw/40017134-2042011202526098-3285397012161232896-n.jpg"
                  alt="40017134-2042011202526098-3285397012161232896-n"
                  border="0"
                />
              ) : (
                <div className="Profilename">{name[0]}</div>
              )}
            </Styleddivleft>
            <Styleddivright>
              <h2 className="coverh1">{name}</h2>
              <div>
                <p className="coverp">email : {email}</p>
                {/* <p className="coverp">stars</p> */}
                {/* <p>followers : 120</p> */}
                <p className="coverp">Total writeups : {blogs.length}</p>
              </div>
            </Styleddivright>
          </div>
        </div>

        <div className="col-lg-4 col-md-4 col-sm-12 new_profile">
          <h1 className="coreh1">CORE AREA </h1>
          {/* update modal */}
          <div>
            <div className="landingskilldivprofile mb-4">
              <br />

              {loading ? (
                <img className="loading" src={load} />
              ) : corearea != undefined ? (
                corearea
                  .split(",")
                  .map((core) => (
                    <div className="landingskillsmalldivprofile">{core.trim()}</div>
                  ))
              ) : (
                <p>No areas defined !</p>
              )}
              {/* <div className="landingskillsmalldivprofile">NodeJs</div>
                        <div className="landingskillsmalldivprofile">Python</div>
                        <div className="landingskillsmalldivprofile">ML</div>
                        <div className="landingskillsmalldivprofile">Architectures</div> */}
            </div>
          </div>
        </div>

        <div className="col-lg-2 col-md-2 col-sm-12"></div>
      </div>
      {/* <hr className="profilemainhr"/> */}

      <div className="profilecard">
        <h2 className="h2mainprofile mb-4">
          Blogs from <i>{name}</i>
        </h2>
        <div className="profilecardrowmain row mb-4">
          {loading2 ? (
            <img className="loading" src={load} />
          ) : (
            blogs.map((data) => (
              <Profilecard
                title={data.get("title")}
                id={data.id}
                user={data.get("user")}
                theme={data.get("theme")}
                date={data.updatedAt.toLocaleDateString(undefined, options)}
                imgLink={data.get("imageLink")}
              />
            ))
          )}
          {/* <Profilecard />
                <Profilecard />
                <Profilecard /> */}
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Eprofile;
