import React, { useContext,useState,useRef,useEffect } from "react";
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import './login.css';
import Navbar from '../layout/navbar'
import img1 from './im1.jpg'
import Parse from 'parse';
import { AuthContext } from '../../auth';
import {Redirect} from 'react-router-dom'

const Styleddiv = styled.div`
background:#00c7e2;
box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25)
`
const Styledrow = styled.div`
height: -webkit-fill-available
`
const Styledbox = styled.div`
padding: 11px 13px 11px 13px;
`
const Styledform = styled.form`
margin: auto;
width: 78%;
`
const Styledh1 = styled.h1`
position: absolute;
bottom: 0;
color: white;
font-size: 4rem;
margin-left: 3%;`

const Login = () => {
  const [email,setEmail] = useState("")
  const [password,setPassword] = useState("")
  const [loading, setLoading] = useState(false)
  const [logedinuser, setLogedinuser] = useContext(AuthContext)
  const [error,setError] = useState(false)
  const div=<p></p>;


  useEffect(() =>{

    if(localStorage.getItem('id')!=null){
      setLogedinuser('id',localStorage.getItem('id'))
    }

  },[])


  const handleSubmit =(e)=>{
    e.preventDefault();
    setLoading(true);
    const user = Parse.User.logIn(email, password).then((data) =>{
      setError(false)
      setLoading(false);
      setLogedinuser(data.id)
      localStorage.setItem('id',data.id)
    })
    .catch(err => {
      setLoading(false);
      setError(true)
      // alert(err)
      // div = <p>error</p>
    });
  }

  return (
    <div className="updivlogin">
      {(logedinuser != null) && (
          <Redirect to="/blogs" />
      )}
      <Navbar  mainhead="JOURNAL" head="login"/>
      {error == true ? <div class="alert alert-danger alertpopup" style={{'margin':'auto'}} role="alert">
  Couldnt login! Please check your email or password
</div>:
<div>
  </div>}
      <div style={{'textAlign':'center','paddingTop':'5em'}}>
        <img style={{'width':'11vh'}} src={img1} />
      </div>
      <div>
      <div className="loginbox">
            <h1 className="mb-4 text-center" >LOGIN</h1>
            <Styledform onSubmit={handleSubmit}>
            <div class="form-group">
              {/* <label for="exampleInputEmail1">Email address</label> */}
              <input  type="email" 
                      class="form-control inputb"
                      id="exampleInputEmail1"
                      aria-describedby="emailHelp"
                      placeholder="Enter email" 
                      value={email}
                      onChange={e => setEmail(e.target.value)}
                      />
            </div>
            <div class="form-group">
              {/* <label for="exampleInputPassword1">Password</label> */}
              <input  type="password" 
                      class="form-control inputb"
                      id="exampleInputPassword1" 
                      placeholder="Password"
                      value={password}
                      onChange={e => setPassword(e.target.value)}
                      />
            </div>
            <div class="form-group form-check">
              <input type="checkbox" class="form-check-input" id="exampleCheck1" />
              <label class="form-check-label" for="exampleCheck1">Agree to terms and conditions</label>
            </div>
  <button  type="submit" class="btn btn-primary buttonb">{loading?'loading ..':'SIGN IN'}</button>
            <p className="mt-2 text-center" style={{'color':'#585858'}}> New User ? <Link to="/register" style={{'color':'#009688'}}>SIGN UP</Link></p>
          </Styledform>
          </div>
      </div>
      <div className="loginfoot">
        <p className="loginfootp">Journal &copy; 2019 </p>
      </div>
    </div>
  );
}

export default Login;
