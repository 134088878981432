import React from "react";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <div className="mt-4 pb-4" style={{ background: "#ececec" }}>
      <br/>
      <div className="container pt-4 mt-4 mb-4">
        <div className="row">
          <div className="col-lg-6">
            <h2 className="font-weight-bold">Vansh Kapoor</h2>
            <div></div>
            <p>
              Having expertise in full stack development and app development.
              Currently working in Thoughtworks as Application Developer Consultant on
              amazing production applications and processes.
            </p>
          </div>
          <div className="col-lg-6">
            <p>
              Email me at : <a href="mailto:vanshkapoorvk7@gmail.com">vanshkapoorvk7@gmail.com</a>
            </p>
            <div className="d-flex">
              <a className="footerIcons pr-2" target="_blank" href="https://www.linkedin.com/in/vansh-kapoor-62a938169">
                  <i class="fab fa-linkedin"></i>{" "}
                </a>
              <a className="footerIcons pl-2" target="_blank" href="https://github.com/vanshkapoor">
                <i class="fab fa-github"></i>{" "}
              </a>
            </div>
              
          </div>
        </div>
      </div>
      <br/>
    </div>
  );
};

export default Footer;
